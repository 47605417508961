.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh; /* Ensure the page takes up the full viewport height */
  box-sizing: border-box;
  background-color: #2d2d2d; /* Dark gray background */
  color: #f0f0f0; /* Mostly white text */
}

.site-description {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #e0e0e0; /* Slightly lighter white for the description */
}

.site-description h1 {
  margin-bottom: 20px; /* Space below the heading */
  font-size: 40px;
}

.site-description p {
  margin: 40px 0; /* Vertical space above and below each <p> */
}


form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adds space between form elements */
  width: 300px; /* Adjust the width of the form */
  background-color: #3a3a3a; /* Slightly lighter dark gray for form background */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

input,
textarea {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #555; /* Subtle border for inputs */
  border-radius: 10px;
  background-color: #444; /* Dark gray input background */
  color: #f0f0f0; /* White text for inputs */
}

input::placeholder,
textarea::placeholder {
  color: #bbb; /* Subtle placeholder color */
}

textarea {
  resize: vertical; /* Allows resizing of the textarea vertically */
}

button {
  padding: 15px 30px; /* Increased padding for a bigger button */
  font-size: 20px; /* Larger font size for the button text */
  border: none;
  border-radius: 30px; /* More rounded corners */
  background-color: #c0cee9; /* Mint-cyan button background */
  color: #ffffff; /* White text for better contrast */
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
}

button:hover {
  background-color: #a4b6d4; /* Slightly darker blue for hover effect */
  transform: scale(1.05); /* Slightly larger button on hover */
}

h1 {
  margin-bottom: 20px;
  color: #ffffff; /* Bright white for the heading */
}

.footer {
  margin-top: auto;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: #ccc; /* Subtle text color for footer */
}

footer p {
  margin: 0;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
